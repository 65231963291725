/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */



export const listingFields = [
  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'city-bikes', label: 'City Bikes' },
      { option: 'electric-bikes', label: 'Electric Bikes' },
      { option: 'mountain-bikes', label: 'Mountain Bikes' },
      { option: 'childrens-bikes', label: "Children's Bikes" },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Choose an option…',
      isRequired: true,
      requiredMessage: 'You must select a category.',
    },
  },
  {
    key: 'tire',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '29', label: '29' },
      { option: '28', label: '28' },
      { option: '27', label: '27' },
      { option: '26', label: '26' },
      { option: '24', label: '24' },
      { option: '20', label: '20' },
      { option: '18', label: '18' },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Tire size',
      group: 'secondary',
    },
    showConfig: {
      label: 'Tire size',
      isDetail: true,
    },
    saveConfig: {
      label: 'Tire size',
      placeholderMessage: 'Choose an option…',
      isRequired: true,
      requiredMessage: 'You must select a tire size.',
    },
  },
  {
    key: 'brand',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { "option": "cube", label: "Cube" },
      { "option": "diamant", label: "Diamond" },
      { "option": "ghost", label: "Ghost" },
      { "option": "giant", label: "Giant" },
      { "option": "kalkhoff", label: "Kalkhoff" },
      { "option": "kona", label: "Kona" },
      { "option": "otler", label: "Otler" },
      { "option": "vermont", label: "Vermont" }

    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Brand',
      group: 'secondary',
    },
    showConfig: {
      label: 'Brand',
      isDetail: true,
    },
    saveConfig: {
      label: 'Brand',
      placeholderMessage: 'Choose an option…',
      isRequired: true,
      requiredMessage: 'You have to choose a brand.',
    },
  },
  {
    key: 'accessories',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { "option": "bell", label: "Bell" },
      { "option": "lights", label: "Lights" },
      { "option": "lock", label: "Lock" },
      { "option": "mudguard", label: "Mudguard" }
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Accessories',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'Accessories',
    },
    saveConfig: {
      label: 'Additional accessories',
      placeholderMessage: 'Choose an option…',
      isRequired: false,
    },
  },



  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];
export const typeOptions = [
  { key: 'athlete', label: 'Athlete' }, 
  { key: 'SportsTeam', label: 'Team or sportsmanager' },
  { key: 'brand', label: 'Brand' },

]

export const paymentOptionswithStripe = [
  { key: 'invoice', label: 'Pay by invoice' },
  { key: 'payNow', label: 'Pay now' },
]

export const paymentOptionswithClub = [
  { key: 'invoice', label: 'Pay by invoice' },
]

export const paymentOptionsClubFi = [
  { key: 'invoice', label: 'Maksa laskulla' },
]

export const paymentOptionsStripeFi = [
  { key: 'invoice', label: 'Maksa laskulla' },
  { key: 'payNow', label: 'Maksa kortilla' },
]

export const typeOptionsfi = [
  { key: 'athlete', label: 'Urheilija' },
  { key: 'SportsTeam', label: 'Seura tai urheilumanageri' },
  { key: 'brand', label: 'Brändi' },
  
]
export const iconData = [
  { key: 'instagram', label: 'Instagram' },
  { key: 'facebook', label: 'Facebook' },
  { key: 'tiktok', label: 'TikTok' },
  { key: 'linkedin', label: 'LinkedIn' },
  { key: 'twitter', label: 'Twitter' },
];

export const optionsData =
  [
    { key: 'athlete', label: 'Athlete' },
    { key: 'team', label: 'Team' },
  ]


export const optionsDatafi =
  [
    { key: 'athlete', label: 'Urheilijaa' },
    { key: 'team', label: 'Joukkuetta' },
  ]
export const lifestyleInterestData = [
  { key: "reading_books", label: "Reading Books" },
  { key: "singing", label: "Singing" },
  { key: "dancing", label: "Dancing" },
  { key: "painting", label: "Painting" },
  { key: "photography", label: "Photography" },
  { key: "cooking", label: "Cooking" },
  { key: "baking", label: "Baking" },
  { key: "gardening", label: "Gardening" },
  { key: "knitting", label: "Knitting" },
  { key: "sewing", label: "Sewing" },
  { key: "diy_crafts", label: "DIY Crafts" },
  { key: "movies_TVSeries", label: "Movies/TV Series" },
  { key: "traveling", label: "Traveling" },
  { key: "languages", label: "Languages" },
  { key: "yoga_meditation", label: "Yoga and Meditation" },
  { key: "volunteering", label: "Volunteering" },
  { key: "home_decor", label: "Home Decor" },
  { key: "fashion", label: "Fashion" },
  { key: "astronomy_stargazing", label: "Astronomy and Stargazing" },
  { key: "social_media_management", label: "Social Media Management" },
  { key: "blogging", label: "Blogging" },
  { key: "scuba_diving", label: "Scuba Diving" },
  { key: "entrepreneurship", label: "Entrepreneurship" },
  { key: "mentoring_coaching", label: "Mentoring and Coaching" },
  { key: "stand_up_comedy", label: "Stand-up Comedy" },
  { key: "philosophy", label: "Philosophy" },
  { key: "animals", label: "Animals" },
  { key: "family", label: "Family" },
  { key: "digital_art_and_graphic_design", label: "Digital Art and Graphic Design" },
  { key: "environment", label: "Environment" },
  { key: "mindfulness", label: "Mindfulness" },
  { key: "podcasting ", label: "Podcasting" },
  { key: "science", label: "Science" },
  { key: "music", label: "Music" },
  { key: "sustainable_living", label: "Sustainable Living" },
  { key: "technology", label: "Technology" },
  { key: "outdoor_activities", label: "Outdoor Activities" },
  { key: "performing_arts", label: "Performing Arts" },
  { key: "thrifting_and_vintage", label: "Thrifting and Vintage" },
  { key: "video_games", label: "Video Games" },
  { key: "virtual_reality", label: "Virtual Reality" },
  { key: "woodworking", label: "Woodworking" },
  { key: "zero_waste_living", label: "Zero Waste Living" },
  { key: "astronomy", label: "Astronomy" },
  { key: "writing", label: "Writing" },
  { key: "history", label: "History" }

]

export const lifestyleInterestDataFi = [
  { key: "reading_books", label: "Lukeminen" },
  { key: "singing", label: "Laulaminen" },
  { key: "dancing", label: "Tanssi" },
  { key: "painting", label: "Maalaus" },
  { key: "photography", label: "Valokuvaus" },
  { key: "cooking", label: "Ruoanlaitto" },
  { key: "baking", label: "Leivonta" },
  { key: "gardening", label: "Puutarhanhoito" },
  { key: "knitting", label: "Neulominen" },
  { key: "sewing", label: "Ompeleminen" },
  { key: "diy_crafts", label: "Käsityöt" },
  { key: "movies_TVSeries", label: "Elokuvat/TV-sarjat" },
  { key: "traveling", label: "Matkustus" },
  { key: "languages", label: "Kielet" },
  { key: "yoga_meditation", label: "Jooga ja meditaatio" },
  { key: "volunteering", label: "Vapaaehtoistyö" },
  { key: "home_decor", label: "Sisustaminen" },
  { key: "fashion", label: "Muoti" },
  { key: "astronomy_stargazing", label: "Astrologia" },
  { key: "social_media_management", label: "Sosiaalinen media" },
  { key: "blogging", label: "Bloggaus" },
  { key: "scuba_diving", label: "Sukellus" },
  { key: "entrepreneurship", label: "Yrittäjyys" },
  { key: "mentoring_coaching", label: "Mentorointi/Valmennus" },
  { key: "stand_up_comedy", label: "Stand-up komedia" },
  { key: "philosophy", label: "Filosofia" },
  { key: "animals", label: "Eläimet" },
  { key: "family", label: "Perhe" },
  { key: "digital_art_and_graphic_design", label: "Graafinen suunnittelu" },
  { key: "environment", label: "Ympäristö" },
  { key: "mindfulness", label: "Mindfulness" },
  { key: "podcasting ", label: "Podcastit" },
  { key: "science", label: "Tiede" },
  { key: "music", label: "Musiikki" },
  { key: "sustainable_living", label: "Kestävä elämäntapa" },
  { key: "technology", label: "Teknologia" },
  { key: "outdoor_activities", label: "Ulkoliikunta" },
  { key: "performing_arts", label: "Esittävät taiteet" },
  { key: "thrifting_and_vintage", label: "Kirpputorit ja vintage" },
  { key: "video_games", label: "Videopelit" },
  { key: "virtual_reality", label: "Virtuaalitodellisuus" },
  { key: "woodworking", label: "Puutyöt" },
  { key: "zero_waste_living", label: "Nollapäästö elämäntapa" },
  { key: "astronomy", label: "Tähtitiede" },
  { key: "writing", label: "Kirjoittaminen" },
  { key: "history", label: "Historia" }

]


export const companyInterestValues = [
  { key: "adaptability", label: "Adaptability" },
  { key: "agility", label: "Agility" },
  { key: "continuous_improvement", label: "Continuous Improvement" },
  { key: "customer_centricity", label: "Customer Centricity" },
  { key: "diversity_and_inclusion", label: "Diversity and Inclusion" },
  { key: "efficiency", label: "Efficiency" },
  { key: "employee_development", label: "Employee Development" }, ,
  { key: "innovation", label: "Innovation" },
  { key: "innovative_leadership", label: "Innovative Leadership" },
  { key: "integrity", label: "Integrity" },
  { key: "social_responsibility", label: "Social Responsibility" },
  { key: "sustainability", label: "Sustainability" },
  { key: "transparency", label: "Transparency" }

]

export const companyInterestValuesFi = [
  { key: "customer_centricity", label: "Asiakaskeskeisyys" },
  { key: "adaptability", label: "Sopeutumiskykyinen" },
  { key: "agility", label: "Ketterä" },
  { key: "continuous_improvement", label: "Jatkuva kehitys" },
  { key: "flexible", label: "Joustava" },
  { key: "diversity_and_inclusion", label: "Monimuotoisuus" },
  { key: "efficiency", label: "Tehokkuus" },
  { key: "employee_development", label: "Työntekijöiden kehittäminen" },
  { key: "innovation", label: "Innovatiivinen" },
  // { key: "innovative_leadership", label: "Innovative Leadership" },
  // { key: "integrity", label: "Integrity" },
  { key: "social_responsibility", label: "Sosiaalinen vastuu" },
  { key: "sustainability", label: "Vastuullisuus" },
  { key: "transparency", label: "Läpinäkyvyys" },
  { key: "environmental", label: "Ympäristöystävällinen" },
  { key: "international", label: "Kansainvälinen" },

]
export const sportsData = [
  { key: "football", label: "Football" },
  { key: "basketball", label: "Basketball" },
  { key: "tennis", label: "Tennis" },
  { key: "golf", label: "Golf" },
  { key: "finnish_baseball", label: "Finnish Baseball" },
  { key: "baseball", label: "Baseball" },
  { key: "volleyball", label: "Volleyball" },
  { key: "ice_hockey", label: "Ice Hockey" },
  { key: "rugby", label: "Rugby" },
  { key: "american_football", label: "American Football" },
  { key: "cross_country_running", label: "Cross Country Running" },
  { key: "cricket", label: "Cricket" },
  { key: "swimming", label: "Swimming" },
  { key: "track_and_field", label: "Track and Field" },
  { key: "gymnastics", label: "Gymnastics" },
  { key: "padel", label: "Padel" },
  { key: "curling", label: "Curling" },
  { key: "floorball", label: "Floorball" },
  { key: "bowling", label: "Bowling" },
  { key: "darts", label: "Darts" },
  { key: "cycling", label: "Cycling" },
  { key: "surfing", label: "Surfing" },
  { key: "snowboarding", label: "Snowboarding" },
  { key: "alpine_skiing", label: "Alpine Skiing" },
  { key: "cross_country_skiing", label: "Cross Country Skiing" },
  { key: "table_tennis", label: "Table Tennis" },
  { key: "badminton", label: "Badminton" },
  { key: "martial_arts", label: "Martial Arts" },
  { key: "rowing", label: "Rowing" },
  { key: "synchronized_swimming", label: "Synchronized Swimming" },
  { key: "archery", label: "Archery" },
  { key: "skateboarding", label: "Skateboarding" },
  { key: "canoeing_and_kayaking", label: "Canoeing and Kayaking" },
  { key: "fencing", label: "Fencing" },
  { key: "horseback_riding", label: "Horseback Riding" },
  { key: "triathlon", label: "Triathlon" },
  { key: "diving", label: "Diving" },
  { key: "weightlifting", label: "Weightlifting" },
  { key: "squash", label: "Squash" },
  { key: "figure_skating", label: "Figure Skating" },
  { key: "crossfit", label: "CrossFit" },
  { key: "water_polo", label: "Water Polo" },
  { key: "handball", label: "Handball" },
  { key: "polo", label: "Polo" },
  { key: "disc_golf", label: "Disc Golf" },
  { key: "lacrosse", label: "Lacrosse" },
  { key: "climbing", label: "Climbing" },
  { key: "yoga", label: "Yoga" },
  { key: "ringette", label: "Ringette" },
  { key: "cue_sports", label: "Cue Sports" },
  { key: "orienteering", label: "Orienteering" },
  { key: "motorsports", label: "Motorsports" },
  { key: "e-sports", label: "E-Sports" },
  { key: "fitness", label: "Fitness" },
  { key: "shooting_sports", label: "Shooting Sports" },
  { key: "biathlon", label: "Biathlon" },
  { key: "cheerleading", label: "Cheerleading" },
  { key: "futsal", label: "Futsal" },
  { key: "freestyle_skiing", label: "Freestyle Skiing" },
  { key: "bandy", label: "Bandy" },
  { key: "sailing", label: "Sailing" },
  { key: "synchronized_skating", label: "Synchronized Skating" },
  { key: "ski_jumping", label: "Ski Jumping" },
  { key: "beach_volley", label: "Beach Volleyball" },
  { key: "nordic_combined", label: "Nordic Combined" },
  { key: "other", label: "Other (Specify)" }
];

export const sportsDataFi = [
  { key: "football", label: "Jalkapallo" },
  { key: "basketball", label: "Koripallo" },
  { key: "tennis", label: "Tennis" },
  { key: "golf", label: "Golf" },
  { key: "finnish_baseball", label: "Pesäpallo" },
  { key: "baseball", label: "Amerikkalainen pesäpallo" },
  { key: "volleyball", label: "Lentopallo" },
  { key: "ice_hockey", label: "Jääkiekko" },
  { key: "rugby", label: "Rugby" },
  { key: "american_football", label: "Amerikkalainen jalkapallo" },
  { key: "cross_country_running", label: "Maastojuoksu" },
  { key: "cricket", label: "Kriketti" },
  { key: "swimming", label: "Uiminen" },
  { key: "track_and_field", label: "Yleisurheilu" },
  { key: "gymnastics", label: "Voimistelu" },
  { key: "padel", label: "Padel" },
  { key: "curling", label: "Curling" },
  { key: "floorball", label: "Salibandy" },
  { key: "bowling", label: "Keilaus" },
  { key: "darts", label: "Darts" },
  { key: "cycling", label: "Pyöräily" },
  { key: "surfing", label: "Surffaus" },
  { key: "snowboarding", label: "Lumilautailu" },
  { key: "alpine_skiing", label: "Alppihiihto" },
  { key: "cross_country_skiing", label: "Maastohiihto" },
  { key: "table_tennis", label: "Pöytätennis" },
  { key: "badminton", label: "Sulkapallo" },
  { key: "martial_arts", label: "Kamppailulajit" },
  { key: "rowing", label: "Soutu" },
  { key: "synchronized_swimming", label: "Taitouinti" },
  { key: "archery", label: "Jousiammunta" },
  { key: "skateboarding", label: "Rullalautailu" },
  { key: "canoeing_and_kayaking", label: "Melonta" },
  { key: "fencing", label: "Miekkailu" },
  { key: "horseback_riding", label: "Ratsastus" },
  { key: "triathlon", label: "Triathlon" },
  { key: "diving", label: "Sukellus" },
  { key: "weightlifting", label: "Painonnosto" },
  { key: "squash", label: "Squash" },
  { key: "figure_skating", label: "Taitoluistelu" },
  { key: "crossfit", label: "CrossFit" },
  { key: "water_polo", label: "Vesipallo" },
  { key: "handball", label: "Käsipallo" },
  { key: "polo", label: "Poolo" },
  { key: "disc_golf", label: "Frisbeegolf" },
  { key: "lacrosse", label: "Haavipallo" },
  { key: "climbing", label: "Kiipeily" },
  { key: "yoga", label: "Jooga" },
  { key: "ringette", label: "Ringette" },
  { key: "cue_sports", label: "Biljardi" },
  { key: "orienteering", label: "Suunnistus" },
  { key: "motorsports", label: "Moottoriurheilu" },
  { key: "e-sports", label: "E-Urheilu" },
  { key: "fitness", label: "Fitness" },
  { key: "shooting_sports", label: "Ammunta" },
  { key: "biathlon", label: "Ampumahiihto" },
  { key: "cheerleading", label: "Cheerleading" },
  { key: "futsal", label: "Futsal" },
  { key: "freestyle_skiing", label: "Freestylehiihto" },
  { key: "bandy", label: "Jääpallo" },
  { key: "sailing", label: "Purjehdus" },
  { key: "synchronized_skating", label: "Muodostelmaluistelu" },
  { key: "ski_jumping", label: "Mäkihyppy" },
  { key: "beach_volley", label: "Beach Volley" },
  { key: "nordic_combined", label: "Yhdistetty" },
  { key: "other", label: "Muu" }
];


export const partnershipLengthss = [
  { key: "0-3-month", label: "0-3 months" },
  { key: "3-6-month", label: "3-6 months" },
  { key: "6-12-month", label: "6-12 months" },
  { key: "12-month", label: "Over 12 months" },
]
export const partnershipLength = [
  { key: "0-3-month", label: "0-3 months" },
  { key: "3-6-month", label: "3-6 months" },
  { key: "6-12-month", label: "6-12 months" },
  { key: "12-month", label: "Over 12 months" },
]

export const partnershipLengthFi = [
  { key: "0-3-month", label: "0-3 kk" },
  { key: "3-6-month", label: "3-6 kk" },
  { key: "6-12-month", label: "6-12 kk" },
  { key: "12-month", label: "Yli 12 kk" },
]

export const partnershipStartLengths = [
  { key: "ASAP-1-3-weeks", label: "ASAP 1-3 Weeks" },
  { key: "soon-1-3-months", label: "Soon 1-3 months" },
  { key: "later-4-months+", label: "Later 4 months +" },
  { key: "Not sure", label: "Not sure" },
]

export const partnershipStartLength = [
  { key: "ASAP-1-3-weeks", label: "ASAP 1-3 Weeks" },
  { key: "soon-1-3-months", label: "Soon 1-3 months" },
  { key: "later-4-months+", label: "Later 4 months +" },
  { key: "Not sure", label: "Not sure" },
]
export const partnershipStartLengthFi = [
  { key: "ASAP-1-3-weeks", label: "Heti 1-3 viikkoa" },
  { key: "soon-1-3-months", label: "Pian 1-3 kuukautta" },
  { key: "later-4-months+", label: "Myöhemmin 4+ kuukautta" },
  { key: "Not sure", label: "En tiedä" },
]


export const priceArrayData = [
  { key: "logo_visibilityPrice", label: "Logo", price: "logo_visibilityPrice" },
  { key: "social_mediaPrice", label: "Social Media", price: "social_mediaPrice" },
  { key: "brand_ambassadorPrice", label: "Brand Ambassador", price: "brand_ambassadorPrice" },
  { key: "appearancePrice", label: "Appearance", price: " appearancePrice " },
  { key: "eventsPrice", label: "Event", price: "eventsPrice" }
]

export const priceArrayDatafi = [
  { key: "logo_visibilityPrice", label: "Logonäkyvyys", price: "logo_visibilityPrice" },
  { key: "social_mediaPrice", label: "Sosiaalinen media", price: "social_mediaPrice" },
  { key: "brand_ambassadorPrice", label: "Brändilähettiläs", price: "brand_ambassadorPrice" },
  { key: "appearancePrice", label: "Ulkomuoto", price: "appearancePrice" },
  { key: "eventsPrice", label: "Tapahtuma", price: "eventsPrice" }
]

export const sponsorshipTypeData = [
  { key: "logo_visibility", label: "Logo Visibility" },
  { key: "social_media", label: "Social Media" },
  { key: "brand_ambassador", label: "Brand Ambassador" },
  { key: "appearance", label: "Appearance" },
  { key: "events", label: "Events" }
];

export const sponsorshipTypeDataFi = [
  { key: "logo_visibility", label: "Logonäkyvyys" },
  { key: "social_media", label: "Sosiaalinen media" },
  { key: "brand_ambassador", label: "Brändilähettiläs" },
  { key: "appearance", label: "Esiintyminen" },
  { key: "events", label: "Tapahtuma" }
];
export const brandSponsorshipType = [
  { "key": "logo_visibility", "label": "Logo Visibility" },
  { "key": "social_media", "label": "Social Media" },
  { "key": "brand_ambassador", "label": "Brand Ambassador" },
  { "key": "appearance", "label": "Appearance" }
];

export const brandSponsorshipTypeFi = [
  { "key": "logo_visibility", "label": "Logonäkyvyys" },
  { "key": "social_media", "label": "Sosiaalinen media" },
  { "key": "brand_ambassador", "label": "Brändilähettiläs" },
  { "key": "appearance", "label": "Esiintyminen" }
];

export const termsAndConditionsData = [
  {
    key: "right_to_image", label: "Rights to image and name use for",
    data: [
      { key: "1-3 months", label: "1-3 months" },
      { key: "3-6 months", label: "3-6 months" },
      { key: "6-12 months", label: "6-12 months" },
      { key: "over-12 months", label: "Over 12 months" },
    ],
  },

  {
    key: "must_not_delete_social_media_post_before", label: "The social media publication may not be deleted before",
    data: [
      { key: "1-3 months", label: "1-3 months" },
      { key: "3-6 months", label: "3-6 months" },
      { key: "6-12 months", label: "6-12 months" },
      { key: "over-12 months", label: "Over 12 months" },
    ],
  },
  { key: "cannot_disable_comments_on_post_from_other_users", label: "Cannot Disable Comments on Post from Other Users" },
  { key: "if_using_instagram_no_hide_like_count", label: "If Using Instagram, Do Not Hide Like Count in Feed" },
  { key: "content_approval_before_posting", label: "Content Must Be Approved by the Brand Before Posting" },
  { key: "terms_open_for_negotiation", label: "Terms Open for Negotiation" }
];


export const termsAndConditionsDataFi = [
  {
    key: "right_to_image", label: "Oikeus kuviin ja nimen käyttöön",
    data: [
      { key: "1-3 months", label: "1-3 kuukaudeksi" },
      { key: "3-6 months", label: "3-6 kuukaudeksi" },
      { key: "6-12 months", label: "6-12 kuukaudeksi" },
      { key: "over-12 months", label: "Yli 12 kuukaudeksi" },
    ],
  },

  {
    key: "must_not_delete_social_media_post_before", label: "Sosisaalisen median julkaisua ei saa poistaa",
    data: [
      { key: "1-3 months", label: "1-3 kuukauteen" },
      { key: "3-6 months", label: "3-6 kuukauteen" },
      { key: "6-12 months", label: "6-12 kuukauteen" },
      { key: "over-12 months", label: "Yli 12 kuukauteen" },
    ],
  },
  { key: "cannot_disable_comments_on_post_from_other_users", label: "Ei saa estää kommentointia julkaisussa muilta käyttäjiltä" },
  { key: "if_using_instagram_no_hide_like_count", label: "Ei saa piilottaa Instagramissa tykkäysten määrää" },
  { key: "content_approval_before_posting", label: "Sisältö tulee hyväksyttää brändillä ennen julkaisua" },
  { key: "terms_open_for_negotiation", label: "Ehdoista voidaan neuvotella" }
];

export const descriptionList = [
  { key: "Type of sponsorship?", label: "1.Type of sponsorship?" },
  { key: "Most popular sports?", label: "2.Most popular sports?" },
  { key: "Schedule?", label: "3.Schedule?" },
  { key: "Budget?", label: "4.Budget?" }
]

export const descriptionListFi = [
  { key: "Type of sponsorship?", label: "1.Sponsoroinnin muoto?" },
  { key: "Most popular sports?", label: "2.Mieluisimmat urheilulajit?" },
  { key: "Schedule?", label: "3.Aikataulu?" },
  { key: "Budget?", label: "4.Budjetti?" }
]


///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields This is relevant only to listings using default-inquiry process atm.
 *                        It contains price: true/false value to indicate, whether price should be shown.
 *                        If defaultListingFields.price is not explicitly set to _false_, price will be shown.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
