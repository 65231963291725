export const getUserType = (user) => (user?.attributes?.profile?.publicData?.userType);
export const getListingType = (listing) => (listing?.attributes?.publicData?.listingType);

// export const listingTypesForBrand=[
//     {label :  "Athlete",
//      listingType : "athlete", 
//     unitType: "item"},
//    {label :  "Sport Team",
//    listingType : "sportTeam", 
//   unitType: "item"},
        
// ]