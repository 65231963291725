export const listingFieldFi=[
{
    "key": "sponsorshipType",
    "scope": "public",
    "schemaType": "multi-enum",
    "enumOptions": [
        {
            "label": "Esiintyminen",
            "option": "appearance"
        },
        {
            "label": "Brändilähettiläs",
            "option": "brand_ambassador"
        },
        {
            "label": "Tapahtuma",
            "option": "events"
        },
        {
            "label": "Logonäkyvyys",
            "option": "logo_visibility"
        },
        {
            "label": "Sosiaalinen media",
            "option": "social_media"
        }
    ],
    "filterConfig": {
        "indexForSearch": true,
        "group": "primary",
        "label": " Sponsorointitapa"
    },
    "showConfig": {
        "label": " Sponsorointitapa"
    },
    "saveConfig": {
        "label": " Sponsorointitapa"
    },
    "listingTypeConfig": {
        "limitToListingTypeIds": true,
        "listingTypeIds": [
            "athlete"
        ]
    }
},
{
    "key": "sports",
    "scope": "public",
    "schemaType": "multi-enum",
    "enumOptions": [
        {
            "label": "Alppihiihto",
            "option": "alpine_skiing"
        },
        {
            "label": "Amerikkalainen jalkapallo",
            "option": "american_football"
        },
        {
          "label": 'Ammunta',
         "option": 'shooting_sports'},
        {
            "label": "Ampumahiihto",
            "option": "biathlon"
        },
        {
            "label": "Beach volley",
            "option": "beach_volley"
        },
        {
            "label": "Biljardi",
            "option": "cue_sports"
        },
        {
            "label": "Baseball",
            "option": "baseball"
        },
        {
            "label": "Cheerleading",
            "option": "cheerleading"
        },
        {
            "label": "CrossFit",
            "option": "crossFit"
        },
        {
            "label": "Curling",
            "option": "curling"
        },
        {
            "label": "Darts",
            "option": "darts"
        },
        {
            "label": "E-Urheilu",
            "option": "e_sports"
        },
        {
            "label": "Fitness",
            "option": "fitness"
        },
        {
            "label": "Freestylehiihto",
            "option": "freestyle_skiing"
        },
        {
            "label": "Futsal",
            "option": "futsal"
        },
        {
            "label": "Frisbeegolf",
            "option": "disc_golf"
        },
        {
            "label": "Golf",
            "option": "golf"
        },
        {
            "label": "Jalkapallo",
            "option": "football"
        },
        {
            "label": "Jousiammunta",
            "option": "archery"
        },
        {
            "label": "Jääkiekko",
            "option": "ice_hockey"
        },
        {
            "label": "Jääpallo",
            "option": "bandy"
        },
        {
            "label": "Jooga",
            "option": "yoga"
        },
        {
            "label": "Kamppailulajit",
            "option": "martial_arts"
        },
        {
            "label": "Keilaus",
            "option": "bowling"
        },
        {
            "label": "Kiipeily",
            "option": "climbing"
        },
        {
            "label": "Koripallo",
            "option": "basketball"
        },
        {
            "label": "Kriketti",
            "option": "cricket"
        },
        {
            "label": "Käsipallo",
            "option": "handball"
        },
        {
            "label": "Lacrosse",
            "option": "lacrosse"
        },
        {
            "label": "Lentopallo",
            "option": "volleyball"
        },
        {
            "label": "Maastojuoksu",
            "option": "cross_country_running"
        },
        {
            "label": "Maastohiihto",
            "option": "cross_country_skiing"
        },
        {
            "label": "Melonta",
            "option": "canoeing_and_kayaking"
        },
        {
            "label": "Miekkailu",
            "option": "fencing"
        },
        {
            "label": "Moottoriurheilu",
            "option": "motorsports"
        },
        {
            "label": "Muodostelmaluistelu",
            "option": "synchronized_skating"
        },
        {
            "label": "Mäkihyppy",
            "option": "ski_jumping"
        },
        {
            "label": "Padel",
            "option": "padel"
        },
        {
            "label": "Painonnosto",
            "option": "weightlifting"
        },
        {
            "label": "Pesäpallo",
            "option": "finnish_baseball"
        },
        {
            "label": "Poolo",
            "option": "polo"
        },
        {
            "label": "Purjehdus",
            "option": "sailing"
        },
        {
            "label": "Pöytätennis",
            "option": "table_tennis"
        },
        {
            "label": "Pyöräily",
            "option": "cycling"
        },
        {
            "label": "Ratsastus",
            "option": "horseback_riding"
        },
        {
            "label": "Ringette",
            "option": "ringette"
        },
        {
            "label": "Rugby",
            "option": "rugby"
        },
        {
            "label": "Rullalautailu",
            "option": "skateboarding"
        },
        {
            "label": "Salibandy",
            "option": "floorball"
        },
        {
            "label": "Soutu",
            "option": "rowing"
        },
        {
            "label": "Squash",
            "option": "squash"
        },
        {
            "label": "Suunnistus",
            "option": "orienteering"
        },
        {
            "label": "Sulkapallo",
            "option": "badminton"
        },
        {
            "label": "Surffaus",
            "option": "surfing"
        },
        {
            "label": "Sukellus",
            "option": "diving"
        },
        {
            "label": "Taitoluistelu",
            "option": "figure_skating"
        },
        {
            "label": "Taitouinti",
            "option": "synchronized_swimming"
        },
        {
            "label": "Tanssi",
            "option": "dance"
        },
        {
            "label": "Tennis",
            "option": "tennis"
        },
        {
            "label": "Triathlon",
            "option": "triathlon"
        },
        {
            "label": "Uinti",
            "option": "swimming"
        },
        {
            "label": "Vesipallo",
            "option": "water_polo"
        },
        {
            "label": "Voimistelu",
            "option": "gymnastics"
        },
        {
            "label": "Yhdistetty",
            "option": "nordic_combined"
        },
        {
            "label": "Yleisurheilu",
            "option": "track_and_field"
        },
        {
            "label": "Muut",
            "option": "other"
        }
      ],
    "filterConfig": {
        "indexForSearch": true,
        "group": "primary",
        "label": "Urheilulaji(t)"
    },
    "showConfig": {
        "label": "Urheilulaji(t)"
    },
    "saveConfig": {
        "label": "Urheilulaji(t)"
    },
    "listingTypeConfig": {
        "limitToListingTypeIds": true,
        "listingTypeIds": [
            "athlete",
            "brand"
        ]
    }
},
{
    "key": "lifestyleInterest",
    "scope": "public",
    "schemaType": "multi-enum",
    "enumOptions": [
        {
            "label": "Eläimet",
            "option": "animals"
        },
        {
            "label": "Tähtitiede",
            "option": "astronomy"
        },
        {
            "label": "Leivonta",
            "option": "baking"
        },
        {
            "label": "Bloggaus",
            "option": "blogging"
        },
        {
            "label": "Ruoanlaitto",
            "option": "cooking"
        },
        {
            "label": "Tanssi",
            "option": "dancing"
        },
        {
            "label": "Graafinen suunnittelu",
            "option": "digital_art_and_graphic_design"
        },
        {
            "label": "Käsityöt",
            "option": "diy_crafts"
        },
        {
            "label": "Yrittäjyys",
            "option": "entrepreneurship"
        },
        {
            "label": "Ympäristö",
            "option": "environment"
        },
        {
            "label": "Perhe",
            "option": "family"
        },
        {
            "label": "Muoti",
            "option": "fashion"
        },
        {
            "label": "Puutarhanhoito",
            "option": "gardening"
        },
        {
            "label": "Historia",
            "option": "history"
        },
        {
            "label": "Sisustaminen",
            "option": "home_decor"
        },
        {
            "label": "Neulominen",
            "option": "knitting"
        },
        {
            "label": "Kielet",
            "option": "languages"
        },
        {
            "label": "Mentorointi/valmennus",
            "option": "mentoring_and_coaching"
        },
        {
            "label": "Mindfulness",
            "option": "mindfulness"
        },
        {
            "label": "Elokuvat/TV-sarjat",
            "option": "movies_TVSeries"
        },
        {
            "label": "Musiikki",
            "option": "music"
        },
        {
            "label": "Ulkoliikunta",
            "option": "outdoor_activities"
        },
        {
            "label": "Maalaaminen",
            "option": "painting"
        },
        {
            "label": "Esittävät taiteet",
            "option": "performing_arts"
        },
        {
            "label": "Filosofia",
            "option": "philosophy"
        },
        {
            "label": "Valokuvaus",
            "option": "photography"
        },
        {
            "label": "Podcastit",
            "option": "podcasting"
        },
        {
            "label": "Lukeminen",
            "option": "reading_books"
        },
        {
            "label": "Tiede",
            "option": "science"
        },
        {
            "label": "Laitesukellus",
            "option": "scuba_diving"
        },
        {
            "label": "Laulaminen",
            "option": "singing"
        },
        {
            "label": "Sosiaalinen media",
            "option": "social_media_management"
        },
        {
            "label": "Kestävä elämäntapa",
            "option": "sustainable_living"
        },
        {
            "label": "Teknologia",
            "option": "technology"
        },
        {
            "label": "Kirpputorit ja vintage",
            "option": "thrifting_and_vintage"
        },
        {
            "label": "Matkustaminen",
            "option": "traveling"
        },
        {
            "label": "Videopelit",
            "option": "video_games"
        },
        {
            "label": "Virtuaalinen todellisuus",
            "option": "virtual_reality"
        },
        {
            "label": "Vapaaehtoistyö",
            "option": "volunteering"
        },
        {
            "label": "Elokuvat/TV-sarjat",
            "option": "watching_movies_tv_series"
        },
        {
            "label": "Puutyöt",
            "option": "woodworking"
        },
        {
            "label": "Kirjoittaminen",
            "option": "writing"
        },
        {
            "label": "Jooga ja meditaatio",
            "option": "yoga_and_meditation"
        },
        {
            "label": "Nollapäästö elämäntapa",
            "option": "zero-waste_living"
        }
    ],
    "filterConfig": {
        "indexForSearch": true,
        "group": "primary",
        "label": "Kiinnostuksen kohteet"
    },
    "showConfig": {
        "label": " Kiinnostuksen kohteet "
    },
    "saveConfig": {
        "label": "Kiinnostuksen kohteet "
    },
    "listingTypeConfig": {
        "limitToListingTypeIds": true,
        "listingTypeIds": [
            "athlete"
        ]
    }
},
{
    "key": "company_interest_values",
    "scope": "public",
    "schemaType": "multi-enum",
    "enumOptions": [
        {
            "label": "Sopeutumiskykyinen",
            "option": "adaptability"
        },
        {
            "label": "Ketterä",
            "option": "agility"
        },
        {
            "label": "Jatkuva kehitys",
            "option": "continuous_improvement"
        },
        {
            "label": "Asiakaskeskeisyys",
            "option": "customer_centricity"
        },
        // {
        //     "label": "Customer feedback",
        //     "option": "customer_feedback"
        // },
        {
            "label": "Joustava",
            "option": "flexible"
        },
        {
            "label": "Ympäristöystävällinen",
            "option": "environmental"
        },
        {
            "label": "Kansainvälinen",
            "option": "international"
        },
        {
            "label": "Kasvuyritys",
            "option": "start-up "
        },
        {
            "label": "Monimuotoisuus",
            "option": "diversity_and_Inclusion"
        },
        {
            "label": "Tehokkuus",
            "option": "efficiency"
        },
        {
            "label": "Työntekijöiden kehittäminen",
            "option": "employee_development"
        },
        // {
        //     "label": "Excellence",
        //     "option": "excellence"
        // },
        // {
        //     "label": "Financial prudence",
        //     "option": "financial_prudence"
        // },
        // {
        //     "label": "Global citizenship",
        //     "option": "global_citizenship"
        // },
        // {
        //     "label": "Inclusivity",
        //     "option": "inclusivity"
        // },
        {
            "label": "Innovatiivinen",
            "option": "innovation"
        },
        // {
        //     "label": "Innovative leadership",
        //     "option": "innovative_leadership"
        // },
        // {
        //     "label": "Integrity",
        //     "option": "integrity"
        // },
        {
            "label": "Sosiaalinen vastuu",
            "option": "social_responsibility"
        },
        {
            "label": "Vastuullisuus",
            "option": "sustainability"
        },
        // {
        //     "option": "Tiimityö",
        //     "label": "Teamwork"
        // },
        {
            "label": "Läpinäkyvyys",
            "option": "transparency"
        }
    ],
    "filterConfig": {
        "indexForSearch": true,
        "group": "primary",
        "label": "Yrityksen arvot"
    },
    "showConfig": {
        "label": "Yrityksen arvot"
    },
    "saveConfig": {
        "label": "Yrityksen arvot"
    },
    "listingTypeConfig": {
        "limitToListingTypeIds": true,
        "listingTypeIds": [
            "brand"
        ]
    }
},
{
    "key": "brandSponsorshipType",
    "scope": "public",
    "schemaType": "enum",
    "enumOptions": [
        {
            "label": "Esiintyminen",
            "option": "appearance"
        },
        {
            "label": "Brändilähettiläs",
            "option": "brand_ambassador"
        },
        {
            "label": "Logonäkyvyys",
            "option": "logo_visibility"
        },
        {
            "label": "Sosiaalinen media",
            "option": "social_media"
        }
    ],
    "filterConfig": {
        "indexForSearch": true,
        "group": "primary",
        "label": "Sponsorointitapa"
    },
    "showConfig": {
        "label": "Sponsorointitapa"
    },
    "saveConfig": {
        "label": "Sponsorointitapa"
    },
    "listingTypeConfig": {
        "limitToListingTypeIds": true,
        "listingTypeIds": [
            "brand"
        ]
    }
}
]






export const filterAthleteFieldFi=[
   
    {
        "key": "sports",
        "scope": "public",
        "schemaType": "multi-enum",
        "enumOptions": [
            {
                "label": "Alppihiihto",
                "option": "alpine_skiing"
            },
            {
                "label": "Amerikkalainen jalkapallo",
                "option": "american_football"
            },
            {
                "label": "Jousiammunta",
                "option": "archery"
            },
            {
                "label": "Sulkapallo",
                "option": "badminton"
            },
            {
                "label": "Jääpallo",
                "option": "bandy"
            },
            {
                "label": "Baseball",
                "option": "baseball"
            },
            {
                "label": "Koripallo",
                "option": "basketball"
            },
            {
                "label": "Beach volley",
                "option": "beach_volley"
            },
            {
                "label": "Ampumahiihto",
                "option": "biathlon"
            },
            {
                "label": "Keilaus",
                "option": "bowling"
            },
            {
                "label": "Kiipeily",
                "option": "climbing"
            },
            {
                "label": "Melonta",
                "option": "canoeing_and_kayaking"
            },
            {
                "label": "Cheerleading",
                "option": "cheerleading"
            },
            {
                "label": "Kriketti",
                "option": "cricket"
            },
            {
                "label": "Maastojuoksu",
                "option": "cross_country_running"
            },
            {
                "label": "Maastohiihto",
                "option": "cross_country_skiing"
            },
            {
                "label": "CrossFit",
                "option": "crossFit"
            },
            {
                "label": "Biljardi",
                "option": "cue_sports"
            },
            {
                "label": "Curling",
                "option": "curling"
            },
            {
                "label": "Pyöräily",
                "option": "cycling"
            },
            {
                "label": "Tanssi",
                "option": "dance"
            },
            {
                "label": "Darts",
                "option": "darts"
            },
            {
                "label": "Frisbeegolf",
                "option": "disc_golf"
            },
            {
                "label": "Sukellus",
                "option": "diving"
            },
            {
                "label": "E-Urheilu",
                "option": "e_sports"
            },
            {
                "label": "Miekkailu",
                "option": "fencing"
            },
            {
                "label": "Taitoluistelu",
                "option": "figure_skating"
            },
            {
                "label": "Pesäpallo",
                "option": "finnish_baseball"
            },
            {
                "label": "Fitness",
                "option": "fitness"
            },
            {
                "label": "Salibandy",
                "option": "floorball"
            },
            {
                "label": "Jalkapallo",
                "option": "football"
            },
            {
                "label": "Freestylehiihto",
                "option": "freestyle_skiing"
            },
            {
                "label": "Futsal",
                "option": "futsal"
            },
            {
                "label": "Golf",
                "option": "golf"
            },
            {
                "label": "Voimistelu",
                "option": "gymnastics"
            },
            {
                "label": "Käsipallo",
                "option": "handball"
            },
            {
                "label": "Ratsastus",
                "option": "horseback_riding"
            },
            {
                "label": "Jääkiekko",
                "option": "ice_hockey"
            },
            {
                "label": "Lacrosse",
                "option": "lacrosse"
            },
            {
                "label": "Kamppailulajit",
                "option": "martial_arts"
            },
            {
                "label": "Moottoriurheilu",
                "option": "motorsports"
            },
            {
                "label": "Yhdistetty",
                "option": "nordic_combined"
            },
            {
                "label": "Suunnistus",
                "option": "orienteering"
            },
            {
                "label": "Padel",
                "option": "padel"
            },
            {
                "label": "Poolo",
                "option": "polo"
            },
            {
                "label": "Ringette",
                "option": "ringette"
            },
            {
                "label": "Soutu",
                "option": "rowing"
            },
            {
                "label": "Rugby",
                "option": "rugby"
            },
            {
                "label": "Purjehdus",
                "option": "sailing"
            },
            {
                "label": "Ammunta",
                "option": "shooting_sports"
            },
            {
                "label": "Rullalautailu",
                "option": "skateboarding"
            },
            {
                "label": "Mäkihyppy",
                "option": "ski_jumping"
            },
            {
                "label": "Lumilautailu",
                "option": "snowboarding"
            },
            {
                "label": "Squash",
                "option": "squash"
            },
            {
                "label": "Surffaus",
                "option": "surfing"
            },
            {
                "label": "Uinti",
                "option": "swimming"
            },
            {
                "label": "Muodostelmaluistelu",
                "option": "synchronized_skating"
            },
            {
                "label": "Taitouinti",
                "option": "synchronized_swimming"
            },
            {
                "label": "Pöytätennis",
                "option": "table_tennis"
            },
            {
                "label": "Tennis",
                "option": "tennis"
            },
            {
                "label": "Yleisurheilu",
                "option": "track_and_field"
            },
            {
                "label": "Triathlon",
                "option": "triathlon"
            },
            {
                "label": "Lentopallo",
                "option": "volleyball"
            },
            {
                "label": "Vesipallo",
                "option": "water_polo"
            },
            {
                "label": "Painonnosto",
                "option": "weightlifting"
            },
            {
                "label": "Jooga",
                "option": "yoga"
            },
            {
                "label": "Muu",
                "option": "other"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Urheilulaji(t)"
        },
        "showConfig": {
            "label": "Urheilulaji(t)"
        },
        "saveConfig": {
            "label": "Urheilulaji(t)"
        },
        "listingTypeConfig": {
            "limitToListingTypeIds": true,
            "listingTypeIds": [
                "athlete"  
            ]
        }
    },
 
    {
        "key": "company_interest_values",
        "scope": "public",
        "schemaType": "multi-enum",
        "enumOptions": [
            {
                "label": "Sopeutumiskykyinen",
                "option": "adaptability"
            },
            {
                "label": "Ketterä",
                "option": "agility"
            },
            {
                "label": "Jatkuva kehitys",
                "option": "continuous_improvement"
            },
            {
                "label": "Asiakaskeskeisyys",
                "option": "customer_centricity"
            },
            // {
            //     "label": "Customer feedback",
            //     "option": "customer_feedback"
            // },
            {
                "label": "Joustava",
                "option": "flexible"
            },
            {
                "label": "Ympäristöystävällinen",
                "option": "environmental"
            },
            {
                "label": "Kansainvälinen",
                "option": "international"
            },
            {
                "label": "Kasvuyritys",
                "option": "start-up "
            },
            {
                "label": "Monimuotoisuus",
                "option": "diversity_and_Inclusion"
            },
            {
                "label": "Tehokkuus",
                "option": "efficiency"
            },
            {
                "label": "Työntekijöiden kehittäminen",
                "option": "employee_development"
            },
            // {
            //     "label": "Excellence",
            //     "option": "excellence"
            // },
            // {
            //     "label": "Financial prudence",
            //     "option": "financial_prudence"
            // },
            // {
            //     "label": "Global citizenship",
            //     "option": "global_citizenship"
            // },
            // {
            //     "label": "Inclusivity",
            //     "option": "inclusivity"
            // },
            {
                "label": "Innovatiivinen",
                "option": "innovation"
            },
            // {
            //     "label": "Innovative leadership",
            //     "option": "innovative_leadership"
            // },
            // {
            //     "label": "Integrity",
            //     "option": "integrity"
            // },
            {
                "label": "Sosiaalinen vastuu",
                "option": "social_responsibility"
            },
            {
                "label": "Vastuullisuus",
                "option": "sustainability"
            },
            // {
            //     "option": "Tiimityö",
            //     "label": "Teamwork"
            // },
            {
                "label": "Läpinäkyvyys",
                "option": "transparency"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Yrityksen arvot"
        },
        "showConfig": {
            "label": "Yrityksen arvot"
        },
        "saveConfig": {
            "label": "Yrityksen arvot"
        },
        "listingTypeConfig": {
            "limitToListingTypeIds": true,
            "listingTypeIds": [
                "athlete"
            ]
        }
    },
    {
        "key": "brandSponsorshipType",
        "scope": "public",
        "schemaType": "enum",
        "enumOptions": [
            {
                "label": "Esiintyminen",
                "option": "appearance"
            },
            {
                "label": "Brändilähettiläs",
                "option": "brand_ambassador"
            },
            {
                "label": "Logonäkyvyys",
                "option": "logo_visibility"
            },
            {
                "label": "Sosiaalinen media",
                "option": "social_media"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Sponsorointitapa"
        },
        "showConfig": {
            "label": "Sponsorointitapa"
        },
        "saveConfig": {
            "label": "Sponsorointitapa"
        },
        "listingTypeConfig": {
            "limitToListingTypeIds": true,
            "listingTypeIds": [
                "athlete"
            ]
        }
    }
    ]


export const listingFilterFieldFi=[ 

    {
        "key": "sponsorshipType",
        "scope": "public",
        "schemaType": "multi-enum",
        "enumOptions": [
            {
                "label": "Esiintyminen",
                "option": "appearance"
            },
            {
                "label": "Brändilähettiläs",
                "option": "brand_ambassador"
            },
            {
                "label": "Tapahtuma",
                "option": "events"
            },
            {
                "label": "Logonäkyvyys",
                "option": "logo_visibility"
            },
            {
                "label": "Sosiaalinen media",
                "option": "social_media"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": " Sponsorointitapa"
        },
        "showConfig": {
            "label": " Sponsorointitapa"
        },
        "saveConfig": {
            "label": " Sponsorointitapa"
        },
        "listingTypeConfig": {
            "limitToListingTypeIds": true,
            "listingTypeIds": [
                "athlete"
            ]
        }
    },

    
    {
        "key": "sports",
        "scope": "public",
        "schemaType": "multi-enum",
        "enumOptions": [
            {
                "label": "Alppihiihto",
                "option": "alpine_skiing"
            },
            {
                "label": "Amerikkalainen jalkapallo",
                "option": "american_football"
            },
            {
                "label": "Jousiammunta",
                "option": "archery"
            },
            {
                "label": "Sulkapallo",
                "option": "badminton"
            },
            {
                "label": "Jääpallo",
                "option": "bandy"
            },
            {
                "label": "Baseball",
                "option": "baseball"
            },
            {
                "label": "Koripallo",
                "option": "basketball"
            },
            {
                "label": "Beach volley",
                "option": "beach_volley"
            },
            {
                "label": "Ampumahiihto",
                "option": "biathlon"
            },
            {
                "label": "Keilaus",
                "option": "bowling"
            },
            {
                "label": "Kiipeily",
                "option": "climbing"
            },
            {
                "label": "Melonta",
                "option": "canoeing_and_kayaking"
            },
            {
                "label": "Cheerleading",
                "option": "cheerleading"
            },
            {
                "label": "Kriketti",
                "option": "cricket"
            },
            {
                "label": "Maastojuoksu",
                "option": "cross_country_running"
            },
            {
                "label": "Maastohiihto",
                "option": "cross_country_skiing"
            },
            {
                "label": "CrossFit",
                "option": "crossFit"
            },
            {
                "label": "Biljardi",
                "option": "cue_sports"
            },
            {
                "label": "Curling",
                "option": "curling"
            },
            {
                "label": "Pyöräily",
                "option": "cycling"
            },
            {
                "label": "Tanssi",
                "option": "dance"
            },
            {
                "label": "Darts",
                "option": "darts"
            },
            {
                "label": "Frisbeegolf",
                "option": "disc_golf"
            },
            {
                "label": "Sukellus",
                "option": "diving"
            },
            {
                "label": "E-Urheilu",
                "option": "e_sports"
            },
            {
                "label": "Miekkailu",
                "option": "fencing"
            },
            {
                "label": "Taitoluistelu",
                "option": "figure_skating"
            },
            {
                "label": "Pesäpallo",
                "option": "finnish_baseball"
            },
            {
                "label": "Fitness",
                "option": "fitness"
            },
            {
                "label": "Salibandy",
                "option": "floorball"
            },
            {
                "label": "Jalkapallo",
                "option": "football"
            },
            {
                "label": "Freestylehiihto",
                "option": "freestyle_skiing"
            },
            {
                "label": "Futsal",
                "option": "futsal"
            },
            {
                "label": "Golf",
                "option": "golf"
            },
            {
                "label": "Voimistelu",
                "option": "gymnastics"
            },
            {
                "label": "Käsipallo",
                "option": "handball"
            },
            {
                "label": "Ratsastus",
                "option": "horseback_riding"
            },
            {
                "label": "Jääkiekko",
                "option": "ice_hockey"
            },
            {
                "label": "Lacrosse",
                "option": "lacrosse"
            },
            {
                "label": "Kamppailulajit",
                "option": "martial_arts"
            },
            {
                "label": "Moottoriurheilu",
                "option": "motorsports"
            },
            {
                "label": "Yhdistetty",
                "option": "nordic_combined"
            },
            {
                "label": "Suunnistus",
                "option": "orienteering"
            },
            {
                "label": "Padel",
                "option": "padel"
            },
            {
                "label": "Poolo",
                "option": "polo"
            },
            {
                "label": "Ringette",
                "option": "ringette"
            },
            {
                "label": "Soutu",
                "option": "rowing"
            },
            {
                "label": "Rugby",
                "option": "rugby"
            },
            {
                "label": "Purjehdus",
                "option": "sailing"
            },
            {
                "label": "Ammunta",
                "option": "shooting_sports"
            },
            {
                "label": "Rullalautailu",
                "option": "skateboarding"
            },
            {
                "label": "Mäkihyppy",
                "option": "ski_jumping"
            },
            {
                "label": "Lumilautailu",
                "option": "snowboarding"
            },
            {
                "label": "Squash",
                "option": "squash"
            },
            {
                "label": "Surffaus",
                "option": "surfing"
            },
            {
                "label": "Uinti",
                "option": "swimming"
            },
            {
                "label": "Muodostelmaluistelu",
                "option": "synchronized_skating"
            },
            {
                "label": "Taitouinti",
                "option": "synchronized_swimming"
            },
            {
                "label": "Pöytätennis",
                "option": "table_tennis"
            },
            {
                "label": "Tennis",
                "option": "tennis"
            },
            {
                "label": "Yleisurheilu",
                "option": "track_and_field"
            },
            {
                "label": "Triathlon",
                "option": "triathlon"
            },
            {
                "label": "Lentopallo",
                "option": "volleyball"
            },
            {
                "label": "Vesipallo",
                "option": "water_polo"
            },
            {
                "label": "Painonnosto",
                "option": "weightlifting"
            },
            {
                "label": "Jooga",
                "option": "yoga"
            },
            {
                "label": "Muu",
                "option": "other"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Urheilulaji(t)"
        },
        "showConfig": {
            "label": "Urheilulaji(t)"
        },
        "saveConfig": {
            "label": "Urheilulaji(t)"
        },
        "listingTypeConfig": {
            "limitToListingTypeIds": true,
            "listingTypeIds": [
                "athlete",
                "brand"
            ]
        }
    },
    {
        "key": "lifestyleInterest",
        "scope": "public",
        "schemaType": "multi-enum",
        "enumOptions": [
            {
                "label": "Eläimet",
                "option": "animals"
            },
            {
                "label": "Tähtitiede",
                "option": "astronomy"
            },
            {
                "label": "Leivonta",
                "option": "baking"
            },
            {
                "label": "Bloggaus",
                "option": "blogging"
            },
            {
                "label": "Ruoanlaitto",
                "option": "cooking"
            },
            {
                "label": "Tanssi",
                "option": "dancing"
            },
            {
                "label": "Graafinen suunnittelu",
                "option": "digital_art_and_graphic_design"
            },
            {
                "label": "Käsityöt",
                "option": "diy_crafts"
            },
            {
                "label": "Yrittäjyys",
                "option": "entrepreneurship"
            },
            {
                "label": "Ympäristö",
                "option": "environment"
            },
            {
                "label": "Perhe",
                "option": "family"
            },
            {
                "label": "Muoti",
                "option": "fashion"
            },
            {
                "label": "Puutarhanhoito",
                "option": "gardening"
            },
            {
                "label": "Historia",
                "option": "history"
            },
            {
                "label": "Sisustaminen",
                "option": "home_decor"
            },
            {
                "label": "Neulominen",
                "option": "knitting"
            },
            {
                "label": "Kielet",
                "option": "languages"
            },
            {
                "label": "Mentorointi/valmennus",
                "option": "mentoring_and_coaching"
            },
            {
                "label": "Mindfulness",
                "option": "mindfulness"
            },
            {
                "label": "Elokuvat/TV-sarjat",
                "option": "movies_TVSeries"
            },
            {
                "label": "Musiikki",
                "option": "music"
            },
            {
                "label": "Ulkoliikunta",
                "option": "outdoor_activities"
            },
            {
                "label": "Maalaaminen",
                "option": "painting"
            },
            {
                "label": "Esittävät taiteet",
                "option": "performing_arts"
            },
            {
                "label": "Filosofia",
                "option": "philosophy"
            },
            {
                "label": "Valokuvaus",
                "option": "photography"
            },
            {
                "label": "Podcastit",
                "option": "podcasting"
            },
            {
                "label": "Lukeminen",
                "option": "reading_books"
            },
            {
                "label": "Tiede",
                "option": "science"
            },
            {
                "label": "Laitesukellus",
                "option": "scuba_diving"
            },
            {
                "label": "Laulaminen",
                "option": "singing"
            },
            {
                "label": "Sosiaalinen media",
                "option": "social_media_management"
            },
            {
                "label": "Kestävä elämäntapa",
                "option": "sustainable_living"
            },
            {
                "label": "Teknologia",
                "option": "technology"
            },
            {
                "label": "Kirpputorit ja vintage",
                "option": "thrifting_and_vintage"
            },
            {
                "label": "Matkustaminen",
                "option": "traveling"
            },
            {
                "label": "Videopelit",
                "option": "video_games"
            },
            {
                "label": "Virtuaalinen todellisuus",
                "option": "virtual_reality"
            },
            {
                "label": "Vapaaehtoistyö",
                "option": "volunteering"
            },
            {
                "label": "Elokuvat/TV-sarjat",
                "option": "watching_movies_tv_series"
            },
            {
                "label": "Puutyöt",
                "option": "woodworking"
            },
            {
                "label": "Kirjoittaminen",
                "option": "writing"
            },
            {
                "label": "Jooga ja meditaatio",
                "option": "yoga_and_meditation"
            },
            {
                "label": "Nollapäästö elämäntapa",
                "option": "zero-waste_living"
            }
        ],
        "filterConfig": {
            "indexForSearch": true,
            "group": "primary",
            "label": "Kiinnostuksen kohteet"
        },
        "showConfig": {
            "label": " Kiinnostuksen kohteet "
        },
        "saveConfig": {
            "label": "Kiinnostuksen kohteet "
        },
        "listingTypeConfig": {
            "limitToListingTypeIds": true,
            "listingTypeIds": [
                "athlete"
            ]
        }
    }, 
    
    ]


    