//Action types

import { useSelector } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  getParticularContentDetailsApi,
  getPhylloAccountApi,
} from '../../util/api';

import { createStripeAccountApi } from '../../util/api';

import { storableError } from '../../util/errors';


export const FETCH_SOCIAL_PROFILE_REQUEST = 'app/SocialMediaProfilePage/FETCH_SOCIAL_PROFILE_REQUEST';
export const FETCH_SOCIAL_PROFILE_SUCCESS = 'app/SocialMediaProfilePage/FETCH_SOCIAL_PROFILE_SUCCESS';
export const FETCH_SOCIAL_PROFILE_ERROR = 'app/SocialMediaProfilePage/FETCH_SOCIAL_PROFILE_ERROR';

export const FETCH_ALL_CONTENTS_REQUEST = 'app/SocialMediaProfilePage/FETCH_ALL_CONTENTS_REQUEST';
export const FETCH_ALL_CONTENTS_SUCCESS = 'app/SocialMediaProfilePage/FETCH_ALL_CONTENTS_SUCCESS';
export const FETCH_ALL_CONTENTS_ERROR = 'app/SocialMediaProfilePage/FETCH_ALL_CONTENTS_ERROR';


//InitialState
const initialState = {
  profileDetails: [],
  fetchProfileDetailsInProgress: false,
  fetchProfileDetailsError: null,
  allContectDetails: [],
  fetchAllContentInprogress: false,
  fetchAllContentError: null

};

//Selectors

export const checkScrollingDisabled = state => isScrollingDisabled(state);

//reducer function
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case FETCH_SOCIAL_PROFILE_REQUEST:
      return { ...state, fetchProfileDetailsInProgress: true, fetchProfileDetailsError: null };
    case FETCH_SOCIAL_PROFILE_SUCCESS:
      return {
        ...state,
        profileDetails: payload,
        fetchProfileDetailsInProgress: false,
        fetchProfileDetailsError: null,
      };
    case FETCH_SOCIAL_PROFILE_ERROR:
      return { ...state, fetchProfileDetailsInProgress: false, fetchProfileDetailsError: payload };

    case FETCH_ALL_CONTENTS_REQUEST:
      return { ...state, fetchAllContentInprogress: true, fetchAllContentError: null };
    case FETCH_ALL_CONTENTS_SUCCESS:
      return {
        ...state,
        allContectDetails: payload,
        fetchAllContentInprogress: false,
        fetchAllContentError: null,
      };
    case FETCH_ALL_CONTENTS_ERROR:
      return { ...state, fetchAllContentInprogress: false, fetchAllContentError: payload };


    default:
      return state;
  }
}

// ================ Action creators ================ //


export const fetchProfileRequest = () => ({
  type: FETCH_SOCIAL_PROFILE_REQUEST,
});
export const fetchProfileSuccess = data => ({
  type: FETCH_SOCIAL_PROFILE_SUCCESS,
  payload: data,
});

export const fetchProfileError = e => ({
  type: FETCH_SOCIAL_PROFILE_ERROR,
  error: true,
  payload: e,
});

export const fetchAllContentRequest = () => ({
  type: FETCH_ALL_CONTENTS_REQUEST,
});
export const fetchAllContentSuccess = data => ({
  type: FETCH_ALL_CONTENTS_SUCCESS,
  payload: data,
});

export const fetchAllContectError = e => ({
  type: FETCH_ALL_CONTENTS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunk ================ //

// Get profileDetails



export const getProfileDetails = (phylloUserId, currentUserId) => async (dispatch, sdk, getState) => {
  dispatch(fetchProfileRequest());
  try {
    const socialprofileDetails = await getPhylloAccountApi({ user_id: phylloUserId, currentUserId: currentUserId })

    dispatch(fetchProfileSuccess(socialprofileDetails));
    return;
  } catch (error) {
    dispatch(fetchProfileError(storableError(error)));
  }
};

export const getAllContentDetails = (contentIds) => async (dispatch, sdk, getState) => {
  dispatch(fetchAllContentRequest());
  try {
    const allSocialContentDetails = await getParticularContentDetailsApi({ contentIds });
    dispatch(fetchAllContentSuccess(allSocialContentDetails));
    return;
  } catch (error) {
    dispatch(fetchAllContectError(storableError(error)));
  }
};


export const loadData = params => async (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      let { currentUser } = getState().user;
      const { phylloUserId } = currentUser && currentUser?.attributes?.profile?.protectedData || {}
      
      if (phylloUserId) {
        dispatch(getProfileDetails(phylloUserId, currentUser.id.uuid))
      }
      const contentIds = currentUser?.attributes?.profile?.publicData?.socialAccountsContentIds;
      if (contentIds && contentIds.length > 0) {
        dispatch(getAllContentDetails(contentIds))
      }

      return response
    })
    .catch(e => {
      throw e;
    });



};
